<template>
  <v-row style="height: 100vh;" class="background" justify="center" align="center" >
   
    <v-col cols="12" sm="6" md="4" lg="3">
      <router-view />
    </v-col>
  </v-row>
</template>

<script>
// import { mapState, mapActions } from "vuex";

export default {
  name: "BaseUser",

  methods: {
    // ...mapActions("general", [""]),
  },
  computed: {
    // ...mapState("general", ["notifications"])
  },
};

</script>

<style scope>
.base-user {
  font-family: "Montserrat", sans-serif;
  height: 100vh;
}

.background {
  background-image: url("/Login.jpg") ;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.base-box {
  border-radius: 5px;
}

.base-box > .title {
  color: #1f1f1f;
}
</style>
